import { Link } from 'gatsby';
import PropTypes from 'prop-types';

/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';

import logoMarkBlue from '../../img/logo_mark_blue.svg';
import logoMarkWhite from '../../img/logo_mark_white.svg';
import {
  navbarItem,
  popdownContainer,
  hidden,
  whitePopdown,
  topBar,
  menuButton,
  menuBackdrop,
  logoMarkSmallTop,
  logoBackdrop,
  menuContent,
} from './PopDownNavBar.module.scss';

class PopDownNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.menuOpen = false;
  }

  menuToggle() {
    this.menuOpen = !this.menuOpen;
    // Rewrote this due to classList.toggle(..., bool) not being supported in IE
    if (this.menuOpen) {
      document.documentElement.classList.add('noscroll');
    } else {
      document.documentElement.classList.remove('noscroll');
    }

    // Toggle the checkbox
    document.getElementById('menu-button-pop-down').checked = this.menuOpen;
  }

  render() {
    const { colour, isVisible } = this.props;
    const containerStyles = [popdownContainer];
    if (!isVisible) {
      containerStyles.push(hidden);
    }

    if (colour === 'White') {
      containerStyles.push(whitePopdown);
    }

    return (
      <div className={containerStyles.join(' ')}>
        <div className={[topBar].join(' ')}>
          <div className={menuButton}>
            <input
              type="checkbox"
              id="menu-button-pop-down"
              name="menu-button-pop-down"
              onClick={() => {
                this.menuToggle();
              }}
            />
            <label htmlFor="menu-button-pop-down">
              <div className={menuBackdrop} />
              <Link to="/" title="Lab3 Apps">
                <div className={logoMarkSmallTop}>
                  <img alt="Lab3 Logo Mark" src={logoMarkBlue} />
                  <img alt="Lab3 Logo Mark" src={logoMarkWhite} />
                </div>
              </Link>
              <div className={logoBackdrop}>
                <img alt="Lab3 Logo Background" src={logoMarkBlue} />
              </div>
              <div className={[menuButton].join(' ')}>
                <div />
                <div />
                <div />
              </div>
              <div
                className={menuContent}
                onClick={() => {
                  this.menuToggle();
                }}
              >
                <Link className={navbarItem} to="/case_studies">
                  <div>Our Work</div>
                </Link>
                <Link className={navbarItem} to="/about-us">
                  <div>About Us</div>
                </Link>
                <Link className={navbarItem} to="/blog">
                  <div>Blog</div>
                </Link>
                <Link className={navbarItem} to="/contact-us">
                  <div>Contact</div>
                </Link>
              </div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

PopDownNavbar.propTypes = {
  colour: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
};

PopDownNavbar.defaultProps = {
  colour: 'Blue',
};

export default PopDownNavbar;
