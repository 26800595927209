// extracted by mini-css-extract-plugin
export var contactBody = "Footer-module--contact-body--5jh3M";
export var contactButton = "Footer-module--contact-button--1W7Ky";
export var contactDetails = "Footer-module--contact-details--kDd8u";
export var contactHeader = "Footer-module--contact-header--ugXbo";
export var footer = "Footer-module--footer--rLNBC";
export var heading = "Footer-module--heading--7udJN";
export var line = "Footer-module--line--j3lUj";
export var map = "Footer-module--map--kk0aF";
export var mapPin = "Footer-module--map-pin--io47y";
export var navLinks = "Footer-module--nav-links--d60h0";
export var social = "Footer-module--social--ec1dw";
export var subHeader = "Footer-module--sub-header--00EV+";
export var topScroll = "Footer-module--top-scroll--J5ejW";