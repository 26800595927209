import PropTypes from 'prop-types';
import React from 'react';
import { ParallaxBanner, withController } from 'react-scroll-parallax';

import bigThreeBlue from '../../img/logo_mark_blue.svg';
import bigThreeWhite from '../../img/logo_mark_white.svg';
import * as styles from './BackdropThree.module.scss';

class BackdropThree extends React.PureComponent {
  componentDidMount() {
    const { parallaxController } = this.props;
    parallaxController.getElements().forEach((e) => {
      e.elInner.style.transform = 'translate3d(0,0,0)';
    });
    setTimeout(() => {
      parallaxController.update();
    }, 50);
  }

  render() {
    return (
      <div>
        <ParallaxBanner
          className={`${styles.headerParallax} ${styles.blueThree}`}
          layers={[
            {
              image: bigThreeBlue,
              amount: 0.5,
            },
          ]}
        />
        <ParallaxBanner
          className={`${styles.headerParallax} ${styles.whiteThree}`}
          layers={[
            {
              image: bigThreeWhite,
              amount: 0.5,
            },
          ]}
        />
      </div>
    );
  }
}

BackdropThree.propTypes = {
  parallaxController: PropTypes.object.isRequired,
};

export default withController(BackdropThree);
