import { Link } from 'gatsby';
import React from 'react';

import logo from '../img/logo_full_white.svg';
import facebook from '../img/social/facebook.svg';
import linkedin from '../img/social/linkedin.svg';
import twitter from '../img/social/twitter.svg';
import arrow from '../img/up-arrow.svg';
import CaseStudyLinks from './CaseStudyLinks';
import * as styles from './Footer.module.scss';

const Footer = class extends React.PureComponent {
  componentDidMount() {}

  render() {
    return (
      <footer className={styles.footer}>
        <div className="container">
          <h2 className={styles.heading}>Stay in touch</h2>
          <p>
            Lab3 Apps is no longer taking on new work, but if you need to get in
            touch with us we still check our emails.
          </p>
          <br />
          <br />
          <div className="columns">
            <div className="column is-12-mobile is-6">
              <h2 className={styles.subHeader}>Christchurch Office</h2>
              <div className={styles.contactDetails}>
                {/* <div className={styles.contactHeader}>PHONE</div>
                <div className={styles.contactBody}>
                  <a href="tel:+64 27 819 1023">+64 27 819 1023</a>
                </div> */}
                <div className={styles.contactHeader}>EMAIL</div>
                <div className={styles.contactBody}>
                  <a href="mailto:hello.nz@lab3apps.com">
                    hello.nz@lab3apps.com
                  </a>
                </div>
                {/* <div className={styles.contactHeader}>ADDRESS</div>
                <div className={styles.contactBody}>
                  <a target="_" href="https://g.page/lab3apps?gm">
                    Level 2, 122 Victoria Street
                    <br />
                    Christchurch
                    <br />
                    New Zealand
                  </a>
                </div>
                <div className={styles.contactHeader} />
                <div className={styles.contactBody}>
                  <a
                    target="_"
                    href="https://g.page/lab3apps?gm"
                    style={{ fontWeight: 'bold' }}
                  >
                    Get directions →
                  </a>
                </div> */}
              </div>
            </div>
            <div className="column is-12-mobile is-6">
              <h2 className={styles.subHeader}>London Office</h2>
              <div className={styles.contactDetails}>
                {/* <div className={styles.contactHeader}>PHONE</div>
                <div className={styles.contactBody}>
                  <a href="tel:+44 7309 833286">+44 7309 833286</a>
                </div> */}
                <div className={styles.contactHeader}>EMAIL</div>
                <div className={styles.contactBody}>
                  <a href="mailto:hello.uk@lab3apps.com">
                    hello.uk@lab3apps.com
                  </a>
                </div>
                {/* <div className={styles.contactHeader}>ADDRESS</div>
                <div className={styles.contactBody}>
                  <a target="_" href="https://g.page/lab3-apps-uk?gm">
                    The Brew Eagle House Co-Working Space
                    <br />
                    163 City Rd, Hoxton
                    <br />
                    London
                    <br />
                    United Kingdom
                  </a>
                </div>
                <div className={styles.contactHeader} />
                <div className={styles.contactBody}>
                  <a
                    target="_"
                    href="https://g.page/lab3-apps-uk?gm"
                    style={{ fontWeight: 'bold' }}
                  >
                    Get directions →
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-12-mobile is-6">
              <Link className={styles.contactButton} to="/contact-us">
                Contact Us
              </Link>
            </div>
          </div>
          <div className={styles.line} />
          <div className="columns">
            <div
              className="column is-12-mobile is-4"
              style={{
                paddingTop: '1.5rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <img src={logo} alt="Lab3" style={{ width: '10em' }} />
              {/* Icon made by <a href="https://www.flaticon.com/authors/dave-gandy" title="Dave Gandy">Dave Gandy</a> */}
              <a href="#main" className={styles.topScroll}>
                Back to the top{' '}
                <img
                  src={arrow}
                  alt=""
                  style={{ height: '14px', marginLeft: '5px' }}
                />
              </a>
            </div>
            <div className="column is-12-mobile is-4">
              <CaseStudyLinks />
            </div>
            <div className="column is-12-mobile is-4">
              <ul className={styles.navLinks}>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy &amp; Cookies</Link>
                </li>
              </ul>

              <div className={styles.social}>
                <a
                  title="facebook"
                  href="https://www.facebook.com/lab3apps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1.5em', height: '1.5em' }}
                  />
                </a>
                <a
                  title="twitter"
                  href="https://twitter.com/lab3apps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1.5em', height: '1.5em' }}
                  />
                </a>
                <a
                  title="linkedin"
                  href="https://www.linkedin.com/company/lab3-limited"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="fas fa-lg"
                    src={linkedin}
                    alt="LinkedIn"
                    style={{ width: '1.5em', height: '1.5em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
