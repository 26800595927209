// extracted by mini-css-extract-plugin
export var hidden = "PopDownNavBar-module--hidden--0QdwH";
export var logoBackdrop = "PopDownNavBar-module--logo-backdrop--dJfzg";
export var logoMarkSmallTop = "PopDownNavBar-module--logo-mark-small-top--34otL";
export var menuBackdrop = "PopDownNavBar-module--menu-backdrop--h4iIX";
export var menuButton = "PopDownNavBar-module--menu-button--D3uJI";
export var menuContent = "PopDownNavBar-module--menu-content--vlZb9";
export var navbarItem = "PopDownNavBar-module--navbar-item--5ql2d";
export var popdownContainer = "PopDownNavBar-module--popdown-container--zrQ1t";
export var showMenuOption = "PopDownNavBar-module--showMenuOption--ZLzyH";
export var showing = "PopDownNavBar-module--showing--kk7sA";
export var topBar = "PopDownNavBar-module--top-bar--q-pMs";
export var whitePopdown = "PopDownNavBar-module--white-popdown--hcfan";