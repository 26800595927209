import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Helmet from 'react-helmet';

import BackdropThree from './BackdropThree/BackdropThree';
import Footer from './Footer';
import Navbar from './Nav_Bar/Navbar';
import PopDownNavbar from './Nav_Bar/PopDownNavBar';
import './all.scss';

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isPopdownVisible: false, popdownColour: 'Blue' };

    this.scrollListener = this.scrollListener.bind(this);

    this.footerSection = React.createRef();
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.scrollListener);
    // Set a short timeout here to make sure the page has finished loading before we calculate the footer position
    setTimeout(() => {
      this.footerPosition = this.footerSection.current.offsetTop;
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
  }

  togglePopdownState(newBool) {
    const { isPopdownVisible } = this.state;
    if (isPopdownVisible !== newBool) {
      this.setState({
        isPopdownVisible: newBool,
      });
    }
  }

  scrollListener() {
    const { popdownHeight } = this.props;
    if (!this.scrolling) {
      window.requestAnimationFrame(() => {
        const scrollContainer = document.body;
        const scrollPosition =
          scrollContainer.scrollTop || document.documentElement.scrollTop;
        this.scrolling = false;
        if (scrollPosition > popdownHeight) {
          this.togglePopdownState(true);
        } else {
          this.togglePopdownState(false);
        }

        if (scrollPosition > this.footerPosition - 55) {
          this.setState((prevState) => {
            if (prevState.popdownColour === 'Blue') {
              return { popdownColour: 'White' };
            }
            return null;
          });
        } else {
          this.setState((prevState) => {
            if (prevState.popdownColour === 'White') {
              return { popdownColour: 'Blue' };
            }
            return null;
          });
        }
      });
      this.scrolling = true;
    }
  }

  render() {
    const { isPopdownVisible, popdownColour } = this.state;
    const { header, footer, popdown, children, backdropThree } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query HeadingQueryTest {
            site {
              siteMetadata {
                title
                header
                description
              }
            }
          }
        `}
        render={(data) => (
          <div id="main">
            <Helmet>
              <html lang="en" />
              <title>{data.site.siteMetadata.title}</title>
              <meta
                name="description"
                content={data.site.siteMetadata.description}
              />

              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/img/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                href="/img/favicon-32x32.png"
                sizes="32x32"
              />
              <link
                rel="icon"
                type="image/png"
                href="/img/favicon-16x16.png"
                sizes="16x16"
              />

              <link
                rel="mask-icon"
                href="/img/safari-pinned-tab.svg"
                color="#124e9a"
              />
              <meta name="theme-color" content="#fff" />

              <meta property="og:type" content="website" />
              <meta
                name="apple-mobile-web-app-title"
                content={data.site.siteMetadata.title}
              />
              <meta
                name="application-name"
                content={data.site.siteMetadata.title}
              />
              <meta
                property="og:title"
                content={`${data.site.siteMetadata.title} | ${data.site.siteMetadata.header}`}
              />
              <meta
                property="og:site_name"
                content={data.site.siteMetadata.title}
              />
              <meta property="og:url" content="/" />
              <meta property="og:image" content="/img/og-image.jpg" />
            </Helmet>
            {backdropThree && <BackdropThree />}
            {header && <Navbar />}
            {children}
            <div ref={this.footerSection}>{footer && <Footer />}</div>
            <CookieConsent
              location="bottom"
              buttonText="OK"
              cookieName="ca1"
              containerClasses="cookie-footer"
              style={{
                background: '#151619d1',
                fontSize: '13px',
              }}
              contentStyle={{
                margin: '8px 15px',
              }}
              acceptOnScroll
              acceptOnScrollPercentage={10}
              buttonStyle={{
                background: '#43c5e4',
                color: '#fff',
                fontSize: '13px',
                margin: '8px 15px',
              }}
              expires={150}
            >
              We use cookies to improve our websites experience. You accept the
              use of these cookies by continuing to use it.{' '}
            </CookieConsent>
            {popdown && (
              <PopDownNavbar
                isVisible={isPopdownVisible}
                colour={popdownColour}
              />
            )}
          </div>
        )}
      />
    );
  }
}

Layout.defaultProps = {
  header: true,
  footer: true,
  popdown: true,
  popdownHeight: 170,
  backdropThree: false,
};

Layout.propTypes = {
  header: PropTypes.bool,
  footer: PropTypes.bool,
  popdown: PropTypes.bool,
  backdropThree: PropTypes.bool,
  children: PropTypes.node.isRequired,
  popdownHeight: PropTypes.number,
};

export default Layout;
