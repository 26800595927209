import { Link } from 'gatsby';
import React from 'react';

import logo from '../../img/logo_full_white.svg';
import {
  navbarContainer,
  navbarItem,
  navItems,
  mainLogo,
} from './Navbar.module.scss';

const Navbar = class extends React.PureComponent {
  componentDidMount() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll('.navbar-burger'),
      0
    );
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const { target } = el.dataset;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  }

  render() {
    return (
      <nav role="navigation" aria-label="main-navigation">
        <div className={navbarContainer}>
          <div className={mainLogo}>
            <Link to="/" title="Lab3 Apps">
              <img src={logo} alt="Lab3 Apps" />
            </Link>
          </div>
          <div className={navItems}>
            <Link className={navbarItem} to="/case_studies">
              <div>Our Work</div>
            </Link>
            <Link className={navbarItem} to="/about-us">
              <div>About Us</div>
            </Link>
            <Link className={navbarItem} to="/blog">
              <div>Blog</div>
            </Link>
            <Link className={navbarItem} to="/contact-us">
              <div>Contact</div>
            </Link>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
